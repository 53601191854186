<script setup lang="ts">
const cookie = useCookie<CookieConsentBase>((useRuntimeConfig()?.public?.cookie?.name ?? 'zbl_consent'));
</script>

<template>
  <div class="z-[90] fixed bottom-4 right-4 flex flex-col justify-center items-end gap-4">
    <BaseText @click="(e: Event) => (cookie = null)" size="xl"
      class="drop-shadow-lg hover:drop-shadow-2xl scale-[1.0] hover:scale-[1.5] [&&]:duration-200 cursor-pointer hover:mb-1 hover:mr-1">
      🍪
    </BaseText>
    <BaseButton href="mailto:zbldoccontrol@d4r7.sk"
      class-name="drop-shadow-lg hover:drop-shadow-2xl scale-[1.0] hover:scale-[1.1] [&&]:duration-200"
      style-name="primary">
      <Icon name="uil:envelope" />
    </BaseButton>
    <BaseButton href="tel:+421949014711"
      class-name="drop-shadow-lg hover:drop-shadow-2xl scale-[1.0] hover:scale-[1.1] [&&]:duration-200"
      style-name="primary">
      <Icon name="uil:phone" />
    </BaseButton>
  </div>
</template>
